<template>
  <div>
    <template v-if="isEntryRoute || isDetailRouteWithoutParameter">
      <entity-entry-header :entityKey="eCurrent.key" :title="$t(`views.${eCurrent.key}.title`)" />
      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-button variant="outline-primary" @click="downloadExcel()">
          <template v-if="isExcelLoading">
              <b-spinner small></b-spinner> Loading...
          </template>
          <template v-else>
              Als Excel herunterladen  <b-icon icon="cloud-download"/>
          </template>
          </b-button>
        </b-col>
      </b-row>
    </template>

    <!-- if this is active route (e.g. /products) -->
    <template v-if="isEntryRoute">
      <entity-entry-table :entityKey="eCurrent.key" :tableSettings="table" searchable>
        <template #cell(orderdate)="data">
          {{ data.cell.value ? $d(new Date(data.cell.value), "compact") : "-" }}
        </template>
      </entity-entry-table>
    </template>

    <!-- if subroute is active route (e.g. /products/detail/:orderid) -->
    <section v-else-if="isDetailRouteWithParameter">
      <entity-detail-header :entityKey="eCurrent.key" :entityId="eCurrent.id">
        <template #default="data">
          <dl class="detailheader-list">
            <dt class="sr-only">{{ $t("components.entity.detailheader.name.label") }}</dt>
            <dd>
              <h1 v-if="data.entity.name">{{ data.entity.name[$i18n.locale] }}</h1>
            </dd>

            <div class="w-100"></div>
            <dt>{{ data.definition.$properties.customerProductId.translations[$i18n.locale].name }}</dt>
            <dd>{{ data.entity.customerProductId }}</dd>
          </dl>
        </template>
      </entity-detail-header>

      <router-view />
    </section>

    <!-- if subroute is active route (e.g. /products/add) -->
    <router-view v-else-if="isDetailRouteWithoutParameter" />
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_UNKNOWN } from "@/constants";

import { TableHelper } from "@/assets/js/helper/table";

import MixinEntityBase from "@/components/mixins/EntityBase";
import EntityEntryHeader from "@/components/entity/EntryHeader";
import EntityEntryTable from "@/components/entity/EntryTable";
import EntityDetailHeader from "@/components/entity/DetailHeader";

const ENTITY_KEY = "products";

export default {
  name: "Products",
  mixins: [MixinEntityBase],
  components: {
    EntityEntryHeader,
    EntityEntryTable,
    EntityDetailHeader,
  },
  data() {
    return {
      entityKey: ENTITY_KEY,
      isExcelLoading: false,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            sorting: [CLASS_TABLE_FIELD_KEY_UNKNOWN, "orderdate"],
            sortable: ["orderdate"],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            class: {
              orderdate: "text-right",
              productstate: "text-right",
            },
            thClass: {},
          },
        },
        events: {
          rowClicked: this.tableRowClicked,
        },
      },
    };
  },
  computed: {
    isEntryRoute() {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find((c) => c.name === this.$options.name) !== undefined;
    },
    isDetailRouteWithParameter() {
      return this.$route.matched[this.$route.matched.length - 1].path.indexOf(`/:${this.eCurrent.paramKey}`) >= 0;
    },
    isDetailRouteWithoutParameter() {
      return !this.isEntryRoute && !this.isDetailRouteWithParameter;
    },
    searchFilters () {
      return this.searchEntityKey ? this.$store.getters[`${this.searchEntityKey}/filters`] : []
    },
    searchEntityKey () {
      return this.$store.getters['gui/getSearchEntityKey']
    }
  },
  methods: {
    tableEntitiesMapper(entities, definition) {
      return entities.map((e) =>
        Object.assign({}, e, {
          $routerLink: {
            name: `${this.$options.name}.Detail`,
            params: {
              [this.eCurrent.paramKey]: e.id,
            },
          },
        })
      );
    },
    tableRowClicked(entity) {
      this.$router.push(entity.$routerLink);
    },
    async downloadExcel() {
      this.isExcelLoading = true; // start loading

      try {
        await this.$store.dispatch(`${this.searchEntityKey}/downloadByFilters`);
      } catch (error) {
        console.error("Error downloading Excel:", error);
      } finally {
        this.isExcelLoading = false; // end loading
      }
    }
  },
  created() {
    TableHelper.rowClassHook((entity) => (entity.$routerLink ? "has-routerlink" : null));
  },
};
</script>

<style lang="scss"></style>
